export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain ? "])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier l'ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aujourd'hui à ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hier à ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas la permission d'écrire dans ce canal."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors-ligne"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre un fichier"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédiger un message"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bas SVP"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux messages"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compression"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser à"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le profil"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["éditer les rôles"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virer"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannir"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier Pseudo:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme lu"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du Serveur"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter le Serveur"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le canal au silence"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver les notifications du canal"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des émojis"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'appels"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de démarrage"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité logicielle"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des Bots"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langage"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des changements"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctions WIP"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer le profil"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relier à Google Drive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier à Google Drive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil détaillé"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations peuvent être vues par n'importe qui."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les paramètres des notifications."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications sonores"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclenche un chouette son pour avoir votre attention"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications Push"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoir des notifications Push même quand l'application est fermée."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importez vos beaux émojis gratuitement ! Les émojis ne doivent pas dépasser 3MB."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les types de fichers supportés sont: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des émojis"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de démarrage de l'application bureau"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez installer la version bureau de l'application afin de pouvoir changer ces paramètres."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer Nertivia au démarrage de l'ordinateur."])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez des programmes ou jeux que vous êtes en train d'utiliser en les ajoutant ci-dessous. Cela remplacera votre statut personnalisé dès lors qu'un programme partagé se lance. Une fois le programme terminé votre statut personnalisé sera de nouveau affiché."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez installer la version bureau de l'application afin de pouvoir utiliser cette fonctionnalité."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des utilisateurs automatisés vous permettra d'utiliser notre API pour personnaliser vos propres Bots aux petits oignons."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des commandes pour laisser les utilisateurs savoir quelles sont les commandes disponibles pour vos Bots"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un Bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer le Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer les commandes"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un lien d'invitation"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier l'URL de l'invitation"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des jetons d'authentification"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer le jeton"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le jeton"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le jeton"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le Bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de supprimer votre compte"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce qui sera supprimé"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce qui ne sera pas supprimé"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos messages envoyés sur des serveurs publiques"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions requises"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter/Supprimer tous les serveurs"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer tous les Bots"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer mon compte"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que vous deviez relancer Nertivia afin d'appliquer les changements. Contribuer en envoyant une PR sur Github."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducteurs"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'apparence de Nertivia."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleurs"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencement date et heure"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème par défaut"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thèmes prédéfinis"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS personalisé"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre de navigation"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les fonctions expérimentales ou inachevées"])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune fonction WIP n'est disponible pour l'instant."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer toutes permissions"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des messages"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des messages dans les canaux du serveur"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les rôles"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour ou supprimer les rôles"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les canaux"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour ou supprimer les canaux"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virer"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dégager un utilisateur"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannir"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dégager un utilisateur et l'empêcher de revenir"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les canaux"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les rôles"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les invitations"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité du serveur"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les notifications"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs bannis"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les utilisateurs"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le serveur"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer vos rôles."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle par défaut"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle du bot"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau rôle"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce rôle ne peut être supprimé/assigné à un autre membre car il a été créé par un bot. Supprimez le bot afin de supprimer ce rôle."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce rôle ne peut être supprimé ou réassigné puisque c'est le rôle par défaut appliqué à tous les membres."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la couleur du rôle"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du rôle"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le rôle"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du rôle..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher le rôle"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Server Settings"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez vos canaux."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le canal"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du canal..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du canal"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est le canal par défaut. Vous pouvez changer le canal par défaut dans la page générale. Les canaux par défaut ne peuvent être supprimés."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créer un nouveau canal"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des messages"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de spam (secondes)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal texte"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer le ban des membres d'ici"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révocation..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer le ban"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul les admins sont capables de voir les invitations de chacun."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une invitation"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation personalisée"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le lien personalisé"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une invitation"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisations"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher un utilisateur"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer le nom du serveur pour confirmer"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les paramètres de notification."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces paramètres s'appliqueront à vous uniquement sans affecter les autres utilisateurs."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les notifications"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans le son"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les notifications"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " est en train d'écrire..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " et ", _interpolate(_list(1)), " sont en train d'écrire..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " et ", _interpolate(_list(2)), " sont en train d'écrire..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " membres sont en train d'écrire..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récents"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes enregistrées"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez afin de changer de statut"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que c'est calme !"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages directs"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveurs"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un contact en inscrivant son pseudo et son tag."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un serveur"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre un serveur en insérant un lien d'invitation."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre propre serveur. Commencez par le nommer."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors ligne"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ligne"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupé"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaite jouer"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Membres (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hors ligne (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts en commun"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badges"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrit le"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendu"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer le contact"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter la requête"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la requête"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveurs en commun"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt !"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour disponible"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié !"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a quitté le serveur"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été viré(e)"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été banni(e)"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a rejoint le serveur !"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au serveur échouée."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode anti spam"])}
  }
}